<template>
  <div class="reviewTable">
    <div class="searchForm">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item style="width: 200px">
          <el-input v-model="formInline.contractNo" placeholder="合同编号" class="input-with-select" clearable> </el-input>
        </el-form-item>
        <el-form-item style="width: 150px">
          <el-input v-model="formInline.search" clearable placeholder="客户姓名/手机号" class="input-with-select"> </el-input>
        </el-form-item>
        <el-form-item style="width: 150px">
          <el-select v-model="formInline.cityId" clearable placeholder="所在城市">
            <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 150px">
          <el-select v-model="formInline.companyId" clearable placeholder="所属公司">
            <el-option :key="item.companyId" v-for="item in optionsCompany" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100px" type="primary" size="medium" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox">
      <el-card>
        <div class="table_header"></div>
        <!-- 租赁合同表格数据 -->
        <div class="table-box">
          <el-table @sort-change="sortWarnTime" :data="tableData" style="width: 100%" border stripe :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              backgroundColor: '#eff3f8',
            }">
            <el-table-column type="index" label="序" width="50">
              <template slot-scope="scope">
                <span>{{ (pagenum - 1) * size + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="contractNo" label="合同编号" width="180"> </el-table-column>
            <el-table-column prop="carframeNo" label="车架号" width="180"> </el-table-column>
            <el-table-column prop="carType" label="适用车型" width="100"> </el-table-column>
            <el-table-column label="公司" width="120">
              <template slot-scope="scope"> {{ scope.row.cityName }}{{ scope.row.companyName }} </template>
            </el-table-column>
            <el-table-column prop="customer" label="客户"> </el-table-column>
            <el-table-column prop="contractAmount" label="合同金额(元)" width="120"> </el-table-column>
            <el-table-column prop="monthRent" label="月租金(元)" width="100"> </el-table-column>
            <el-table-column label="到期提醒(天)" sortable="custom">
              <template slot-scope="scope">
                {{ computerDate(scope.row.endDate, scope.row.contractStatus) }}
              </template>
            </el-table-column>
            <el-table-column prop="signDate" label="签订日期" width="120"> </el-table-column>
            <el-table-column prop="createDate" label="创建时间" width="100"> </el-table-column>
            <el-table-column label="审核状态" width="120">
              <template slot-scope="scope" v-if="scope.row.auditState != null">
                <el-tag :type="scope.row.auditState == 0 ? 'info' : tagsStyle[scope.row.auditState]">
                  {{ auditOptionsStatus[scope.row.auditState].label }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="合同状态" width="120">
              <template slot-scope="scope" v-if="scope.row.contractStatus">
                <el-tag :type="tagsStyle[scope.row.contractStatus - 1]">
                  {{ scope.row.contractStatusStr }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column label="操作" :width="operationWidth ? 270 : 180" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button v-if="reviewType == 1 " v-has-press="['contract:auditPass', 'contract:auditUnPass']" size="mini" type="primary" icon="el-icon-s-check"
                  @click="reviewLease(scope.row.id)">审核</el-button>
                <el-button v-if="reviewType == 2 " v-has-press="['contract:submitAudit']" size="mini" type="primary" icon="el-icon-s-check" @click="submitReviewLease(scope.row.id)">提交审核</el-button>
                <el-button v-if="reviewType == 3 " v-has-press="['contract:auditRelease']" size="mini" type="primary" icon="el-icon-s-check" @click="submitReleaseLease(scope.row.id)">发布</el-button>
                <el-button size="mini" type="success" @click="ReadLese(scope.row.id)">查看</el-button>
                <el-button size="mini" type="danger" @click="removeLese(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </el-card>
    </div>

    <!-- 查看合同详细信息 -->
    <el-dialog class="pafPreviewDialog" :fullscreen="fullscreen" :show-close="true" top="8vh" :visible.sync="PreviewVisible" width="76%" :destroy-on-close="true" @close="hiddlenPreviewDialog">
      <div slot="title">
        <span>合同详情</span>
        <i @click="fullscreen = !fullscreen" class="el-icon-full-screen fullscreenIcon"></i>
      </div>
      <div :class="fullscreen ? 'fullscreenHeight' : 'unfullscreenHeight'">
        <el-row v-if="pdfPathList.length > 0" :gutter="20" class="pafLoading" style="height: 100%">
          <el-col :span="6">
            <div class="pdfListBox">
              <div v-for="item in pdfPathList" :key="item.path" class="pdfItem">
                <img @click="downLoadPdf(item)" class="down-icon-document" src="../../assets/img/downDoc.svg" alt="">
                <span @click="readPdfHandle(item)" :class="item.path === currentPreviewPdfPath ? 'current_title_name' : 'title_name'" size="mini">{{ item.pdfName }}</span>
              </div>
            </div>
            <div class="pdfPostil">
              <div class="postilHeader">
                <div class="potilTitle">审阅批注: </div>
                <div v-if="reviewType == 1" v-has-press="['contract:auditPass', 'contract:auditUnPass']">
                  <el-button v-if="postilDisabled" size="mini" type="primary" @click="handlePostil">批 注</el-button>
                  <el-button v-if="!postilDisabled" size="mini" type="primary" @click="savePostil">保 存</el-button>
                </div>
              </div>
              <div>
                <el-form v-if="isPostil" :model="formPostil" ref="rulePostilForm" :rules="rulesPostil">
                  <el-form-item label="" prop="annotation">
                    <el-input :disabled="postilDisabled" type="textarea" :rows="6" placeholder="请输入批注内容" v-model="formPostil.annotation"></el-input>
                  </el-form-item>
                </el-form>
                <el-empty v-else :image-size="100" description="暂未生成相关审阅批注">
                </el-empty>
              </div>
            </div>
          </el-col>
          <el-col :span="18" style="height: 100%">
            <VuePdf :previewPdfUrl="previewPdfUrl" :fullscreen="fullscreen" />
          </el-col>
        </el-row>
        <div v-else class="pdfEmpty"><el-empty :image-size="200" description="暂未生成任何相关合同"></el-empty></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cityList, companyList, leaseData, deleteLeaseDate, getidleaseData } from "@/api/index.js";
import { downloadBzContractPDF, downloadEscmmContractPDF, downloadHksmsPDF, downloadYcbzContractPDF, downloadFktzsPDF, downloadFpqksmPDF, downloadCzrdfcnhPDF } from "@/api/leasePdf.js";
import { downContractPDF, setAnnotation } from "@/api/contractpdf.js";
import { submitAudit, auditUnPass, auditPass, auditRelease } from "@/api/reviewPdf.js";
import { Loading } from "element-ui";

import { auditOptionsStatus } from "@/plugins/commonData.js";
import { computerDate, formatPdfPath } from "@/plugins/commonMethods.js";

import VuePdf from "./vuePdf.vue";
import { mapGetters } from "vuex";

import _ from "lodash";
export default {
  components: {
    VuePdf,
  },
  name: "",
  props: {
    reviewType: {
      type: String,
      default: "",
    },
    adminAuthority: {
      type: Boolean,
    },
    isAuditor: {
      type: Boolean,
      default: false, // hasAuditor审核员
    },
  },
  data() {
    return {
      formInline: {
        contractNo: "",
        search: "",
        companyId: "",
        cityId: "",
        // reviewType: "",

        expirationReminderSort: null, // 到期日排序
      },

      optionsCity: [], // 城市列表
      optionsCompany: [], // 公司列表

      tableData: [], // 审核合同列表
      pagenum: 1, // 页码
      size: 10, // 单页数据数
      total: 10, // 数据总数

      tagsStyle: ["", "warning", "danger", "success"],
      auditOptionsStatus,

      // isAuditor: false, // 是否是审核员ture 操作员false

      leaseId: "", //查看合同Id
      PreviewVisible: false,
      pdfPathList: [],

      currentPreviewPdfPath: "", //当前查看pdf
      previewPdfUrl: "", // 预览pdf地址
      fullscreen: false, //弹窗全屏

      formPostil: {
        annotation: "",
      },
      isPostil: false, //是否已批注
      postilDisabled: true,
      rulesPostil: {
        annotation: [
          { required: true, message: "请输入批注内容", trigger: "blur" },
          { type: "string", min: 1, max: 500, message: "批注内容不超过500字", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    // 监听城市选项是否为空
    "formInline.cityId"(newVal) {
      console.log(newVal);
      if (newVal == "") {
        this.optionsCompany = [];
        this.formInline.companyId = "";
      } else {
        // 获取公司列表
        this.getcompanyList();
        this.formInline.companyId = "";
      }
    },
  },
  computed: {
    ...mapGetters(["isHasPress"]),
    operationWidth() {
      return (
        (this.isHasPress(["contract:auditPass", "contract:auditUnPass"]) && this.reviewType == 1) ||
        (this.isHasPress(["contract:submitAudit"]) && this.reviewType == 2) ||
        (this.isHasPress(["contract:auditRelease"]) && this.reviewType == 3)
      );
    },
  },
  activated() {
    console.log("进入合同审核组件");

    // 重置列表筛选对象
    const paramsKeys = Object.keys(this.formInline);
    paramsKeys.forEach((ele) => {
      if (ele == "expirationReminderSort") {
        this.formInline[ele] = null;
        return;
      }
      this.formInline[ele] = "";
    });
    this.onSubmit();
  },
  mounted() {
    console.log("表格创建", this.reviewType);

    let cityList = JSON.parse(window.sessionStorage.getItem("cityList"));
    if (!cityList) {
      this.getcityList();
    } else {
      this.optionsCity = JSON.parse(window.sessionStorage.getItem("cityList"));
    }

    // 切换tab
    this.onSubmit();
  },

  methods: {
    //查询资产列表
    onSubmit() {
      let obj = { ...this.formInline, auditState: Number(this.reviewType) };
      //到期提醒 升降序
      console.log(this.formInline, obj);
      leaseData(obj, this.pagenum, this.size).then((res) => {
        const DataList = res.data.dataList;
        DataList.forEach((ele) => {
          ele.signTimestamp = this.$moment(ele.signDate).valueOf();
          // 分割合同金额
          const contractAmount = ele.contractAmount;
          ele.contractAmount = contractAmount != null ? _.toNumber(contractAmount).toLocaleString() : "";
          // 分割月租金
          const monthRent = ele.monthRent;
          ele.monthRent = monthRent != null ? _.toNumber(monthRent).toLocaleString() : "";
        });
        // console.log(DataList);
        this.tableData = DataList;
        this.total = res.data.total;
      });
    },
    // 重置当前页码 刷新合同列表
    resetTablelist() {
      this.pagenum = 1;
      this.size = 10;
      this.onSubmit();
    },
    // 监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.pagenum = 1;
      this.onSubmit(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.onSubmit(); // 发起数据请求
    },
    getcityList() {
      cityList().then((res) => {
        this.optionsCity = res.data.dataList;
        window.sessionStorage.setItem("cityList", JSON.stringify(this.optionsCity));
      });
    },
    getcompanyList() {
      companyList(this.formInline.cityId).then((res) => {
        this.optionsCompany = res.data.dataList;
      });
    },

    /**
     * 数据处理函数
     */

    // 计算过期时间
    computerDate,
    // 剩余提醒时间(sort排序)
    sortWarnTime(custom) {
      switch (custom.order) {
        case "ascending":
          this.formInline.expirationReminderSort = "asc"; // 升序
          break;
        case "descending":
          this.formInline.expirationReminderSort = "desc"; // 降序
          break;
        default:
          this.formInline.expirationReminderSort = null;
          break;
      }

      this.onSubmit();
    },
    // 过滤合同相关pdf
    formatPdfPath,
    reviewLease(id) {
      this.$confirm("请选择是否要将该合同通过审核?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "通过",
        cancelButtonText: "不通过",
        type: "warning",
      })
        .then(async () => {
          await auditPass(id);
          this.resetTablelist();
          this.$message.success("合同审核通过成功");
        })
        .catch(async (err) => {
          if (err === "cancel") {
            await auditUnPass(id);
            this.resetTablelist();
            this.$message.info("合同审核不通过成功");
          }
        });
    },
    submitReviewLease(id) {
      this.$confirm("是否要将该合同提交审核?", "提示", {
        distinguishCancelAndClose: false,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await submitAudit(id);
          this.resetTablelist();
          this.$message.success("提交审核成功");
        })
        .catch((err) => {
          if (err == "cancel")
            this.$message({
              type: "info",
              message: "已取消提交",
            });
        });
    },
    submitReleaseLease(id) {
      this.$confirm("是否要将该合同提交发布?", "提示", {
        distinguishCancelAndClose: false,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await auditRelease(id);
          this.resetTablelist();
          this.$message.success("发布成功");
        })
        .catch((err) => {
          if (err == "cancel")
            this.$message({
              type: "info",
              message: "已取消发布",
            });
        });
    },
    //查看
    ReadLese(leaseId) {
      // console.log("合同id" + leaseId);
      this.leaseId = leaseId;
      getidleaseData(leaseId).then((res) => {
        //保证合同
        // let bzPdfPath = res.data.bzPdfPath;
        // res.data.bzPdfPath = this.formatPdfPath(bzPdfPath, "保证合同");
        const pdfPathList = [
          { path: "pdfPath", name: "" },
          { path: "escmmPdfPath", name: "二手车买卖合同" },
          { path: "hksmsPdfPath", name: "还款说明书" },
          { path: "bzPdfPath", name: "保证合同" },
          { path: "ycbzPdfPath", name: "有偿保证合同" },
          { path: "fktzsPdfPath", name: "付款通知书" },
          { path: "fpqksmPdfPath", name: "发票情况说明" },
          { path: "czrdfcnhPdfPath", name: "承租人单方承诺函" },
        ];

        this.pdfPathList = pdfPathList.filter((item) => {
          let Path = res.data[item.path];
          if (Path !== null || Path !== undefined || Path != "") {
            return (item.pdfName = this.formatPdfPath(Path, item.name));
          }
        });

        if (this.pdfPathList.length > 0) {
          this.currentPreviewPdfPath = this.pdfPathList[0].path;
          this.previewPdf(this.currentPreviewPdfPath);
          this.PreviewVisible = true;
        } else {
          this.currentPreviewPdfPath = "";
          this.PreviewVisible = true;
        }

        // 批注状态
        if (res.data.annotation === null) {
          this.isPostil = false;
          this.formPostil.annotation = "";
        } else {
          this.isPostil = true;
          this.formPostil.annotation = res.data.annotation;
        }

        // console.log(pdfPathList);
      });
    },
    // 删除合同信息
    removeLese(id) {
      this.$confirm("此操作将删除该租赁合同信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteLeaseDate(id).then((res) => {
            console.log(res);
            this.resetTablelist();
            this.$message.success("已成功删除");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    previewPdf(path, pdfName) {
      switch (path) {
        case "pdfPath":
          this.handleDownloadRequest(downContractPDF, pdfName);
          break;
        case "bzPdfPath":
          this.handleDownloadRequest(downloadBzContractPDF, pdfName);
          break;
        case "ycbzPdfPath":
          this.handleDownloadRequest(downloadYcbzContractPDF, pdfName);
          break;
        case "escmmPdfPath":
          this.handleDownloadRequest(downloadEscmmContractPDF, pdfName);
          break;
        case "hksmsPdfPath":
          this.handleDownloadRequest(downloadHksmsPDF, pdfName);
          break;
        case "fktzsPdfPath":
          this.handleDownloadRequest(downloadFktzsPDF, pdfName);
          break;
        case "fpqksmPdfPath":
          this.handleDownloadRequest(downloadFpqksmPDF, pdfName);
          break;
        case "czrdfcnhPdfPath":
          this.handleDownloadRequest(downloadCzrdfcnhPDF, pdfName);
          break;
        default:
          break;
      }
    },
    async handleDownloadRequest(downloadFunction, pdfName) {
      try {
        let loadingDown = Loading.service({
          lock: true,
          text: "加载中...",
          target: document.querySelector(".pafPreviewDialog .pafLoading"),
        });
        const res = await downloadFunction(this.leaseId);
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        this.previewPdfUrl = url + "#toolbar=0&navpanes=0&statusbar=0";
        if (pdfName) {
          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", pdfName);
          document.body.appendChild(dom);
          dom.click();
          loadingDown.close();
        } else {
          loadingDown.close();
        }
      } catch (err) {
        // Handle error
      }
    },
    // 切换pdf
    readPdfHandle(item) {
      this.currentPreviewPdfPath = item.path;
      this.previewPdf(item.path);
    },
    hiddlenPreviewDialog() {
      this.leaseId = "";
      this.previewPdfUrl = "";
      this.currentPreviewPdfPath = "";
      this.fullscreen = false;
      this.isPostil = false;
      this.postilDisabled = true;
    },

    // 更新审阅批注
    handlePostil() {
      this.isPostil = true;
      this.postilDisabled = false;
    },
    savePostil() {
      this.$refs.rulePostilForm.validate((valid) => {
        console.log(valid, this.formPostil);
        if (valid) {
          setAnnotation(this.formPostil, this.leaseId).then((res) => {
            this.$message.success("合同审阅批注成功");
            this.postilDisabled = true;
          });
        }
      });
    },
    downLoadPdf(item) {
      this.previewPdf(item.path, item.pdfName);
    },
  },
};
</script>

<style lang="less" scoped>
.reviewTable {
  .table_header {
    padding-bottom: 16px;
  }
  .table-box {
    /deep/.el-table th > .cell {
      text-align: center;
    }

    /deep/.el-table .cell {
      text-align: center;
    }
  }

  .el-col-6 {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .pdfListBox {
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    min-height: 320px;
    .pdfItem {
      width: 100%;
      border: 1px solid #e8eef5;
      margin-bottom: 10px;
      padding-left: 6px;
      padding-top: 2px;
      padding-bottom: 2px;
      box-sizing: border-box;
      cursor: pointer;
      background: #f1f5ff;
      font-size: 12px;
      display: flex;
      align-items: center;
      .title_name {
        margin-left: 6px;
      }
      .current_title_name {
        margin-left: 2px;
        color: rgb(64, 158, 255);
      }

      .down-icon-document {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }

      .down-icon-document:hover {
        box-shadow: 0px 0px 2px #409eff;
      }
    }

    /deep/ .el-button {
      width: 100%;
      text-align: left;
    }
  }

  .pdfPostil {
    flex-grow: 1;
    margin-top: 20px;
    font-size: 12px;

    .postilHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .potilTitle {
        font-size: 14px;
        font-weight: 500;
      }

      /deep/ .el-button--mini,
      .el-button--mini.is-round {
        padding: 4px 10px;
      }
    }

    .el-empty {
      padding-top: 20px;
    }

    /deep/ .el-empty__description p {
      color: #9093998a;
      font-size: 12px !important;
    }
  }
}

.pafPreviewDialog /deep/ .el-dialog__body {
  padding: 20px;
}

.unfullscreenHeight {
  height: calc(90vh - 102px);
}

.fullscreenHeight {
  height: calc(100vh - 102px);
}

.pdfEmpty {
  padding-top: 20vh;
}

.EmptyIframe {
  width: 100%;
  height: 100%;
  background: #f1f3f7;
}

.fullscreenIcon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
}
</style>
