<template>
  <div class="pdfBox" ref="PdfEmbed">
    <div class="pdfIframe" v-show="previewPdfUrl != ''" style="height: 100%">
      <iframe frameborder="0" :src="previewPdfUrl" style="width: 100%; height: 100%"></iframe>
      <noscript>您的浏览器暂不支持预览PDF文档,请安装谷歌浏览器访问该功能!</noscript>
    </div>
    <div v-loading="previewPdfUrl == '' ? true : false" v-show="previewPdfUrl == ''" class="EmptyIframe"></div>
    <!-- <div v-loading="isLoading" v-if="previewPdfUrl == ''" class="EmptyIframe"></div>
    <div v-else :class="fullscreen ? 'fullscreen' : 'unfullscreen'">
      <vue-pdf-embed @rendered="handleDocumentRender" :source="{url: previewPdfUrl}" />
    </div> -->
  </div>
</template>

<script>
// import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "",
  props: {
    previewPdfUrl: {
      type: String,
      default: "",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // VuePdfEmbed,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  watch: {
    previewPdfUrl() {
      this.$nextTick(() => {
        this.$refs.PdfEmbed.scrollTop = 0;
      });
    },
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.pdfBox {
  width: 100%;
  height: 100%;
  //  border: 1px solid black;
  // overflow: hidden;
  // overflow-y: auto;
  box-sizing: border-box;
  background: #ccc;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);

  /deep/ .vue-pdf-embed > div {
    margin: 10px 0;
    width: 100%;
  }
  /deep/ .vue-pdf-embed .vue-pdf-embed__page {
    display: flex;
    justify-content: center;
  }

  .unfullscreen /deep/ .vue-pdf-embed .vue-pdf-embed__page canvas {
    width: calc(100% - 16px) !important;
  }

  .fullscreen /deep/ .vue-pdf-embed .vue-pdf-embed__page canvas {
    width: calc(100% - 32px) !important;
    transform: scale(1) !important;
  }
  .EmptyIframe {
    width: 100%;
    height: 100%;
    background: #f1f3f7;
  }
}
</style>
