import { request } from "@/utils/request";

/**
 *
 * @param {审核合同管理} id
 * @returns
 */

// 提交审核
export function submitAudit(id) {
  return request({
    url: `/api/manage/contract/submitAudit/${id}`,
    method: "post",
  });
}

// 审核不通过
export function auditUnPass(id) {
  return request({
    url: `/api/manage/contract/auditUnPass/${id}`,
    method: "post",
  });
}

// 审核通过
export function auditPass(id) {
  return request({
    url: `/api/manage/contract/auditPass/${id}`,
    method: "post",
  });
}

// 发布合同
export function auditRelease(id) {
  return request({
    url: `/api/manage/contract/auditRelease/${id}`,
    method: "post",
  });
}
