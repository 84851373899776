<template>
  <div class="reviewLease">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">合同审核管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div>
        <el-tabs v-model="activeProperty" type="card">
          <el-tab-pane label="待审核合同" name="1">
            <ReviewTable v-if="activeProperty == 1" :reviewType="activeProperty" :adminAuthority="AdminAuthority" :isAuditor="isAuditor" />
          </el-tab-pane>
          <el-tab-pane label="待修改合同" name="2">
            <ReviewTable v-if="activeProperty == 2" :reviewType="activeProperty" :adminAuthority="AdminAuthority" :isAuditor="isAuditor" />
          </el-tab-pane>
          <el-tab-pane label="待发布合同" name="3">
            <ReviewTable v-if="activeProperty == 3" :reviewType="activeProperty" :adminAuthority="AdminAuthority" :isAuditor="isAuditor" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>

<script>
import ReviewTable from "./commons/reviewTable.vue";

export default {
  name: "reviewLease",
  components: {
    ReviewTable,
  },
  data() {
    return {
      activeProperty: "1", // 1待审核 2待修改 3待发布
      AdminAuthority: false, //是否是管理员
      isAuditor: false, //审核员
    };
  },
  methods: {},
  mounted() {
    this.AdminAuthority = JSON.parse(window.sessionStorage.getItem("userInfo"))[0].userInfo.hasAdmin;
    this.isAuditor = JSON.parse(window.sessionStorage.getItem("userInfo"))[0].userInfo.hasAuditor ?? false;
  },
};
</script>

<style lang="less" scoped>
.reviewLease {
  padding: 60px 20px 20px 20px;
}
</style>
